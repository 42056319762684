import AuthContext from '../../utils/auth0/AuthContext'
import React from 'react'

const withAuth = Component =>
  props => (
    <AuthContext.Consumer>
      {context => <Component {...context} {...props} />}
    </AuthContext.Consumer>
  )

export default withAuth
