import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'

const inMemoryCache = new InMemoryCache()

persistCache({
  cache: inMemoryCache,
  storage: window.localStorage
})

export default inMemoryCache
