import { registerEcommerceEvent, registerProductView } from '../../utils/commons/helpers'

import ChargebeeContext from './ChargebeeContext'
import PropTypes from 'prop-types'
import React from 'react'
import withAccount from '../../components/hocs/withAccount'
import withAuth from '../../components/hocs/withAuth'
import withDependencies from '../../components/hocs/withDependencies'

class ChargebeeProvider extends React.Component {
  constructor (props) {
    super(props)

    this.initChargebee = () => {
      try {
        this.chargebee = window.Chargebee.getInstance()
      } catch (error) {
        window.Chargebee.init({
          site: process.env.GATSBY_CHARGEBEE_SITE,
          enableGATracking: true
        })
        this.chargebee = window.Chargebee.getInstance()
      }

      this.portal = this.chargebee.createChargebeePortal()
      this.cart = this.chargebee.getCart()

      this.chargebee.setPortalCallbacks({
        close: () => {
          this.props.updateAccount(true)
        }
      })

      if (this.props.isAuthenticated) {
        this.cart.setCustomer({ email: this.props.getUserEmail() })
      }
    }

    this.showCustomerPortal = () => {
      this.portal.open()
    }

    this.showCheckout = (productId, productName, productPrice, getHostedPage, callback) => {
      this.chargebee.openCheckout({
        hostedPage: getHostedPage,
        close: function () {
          registerEcommerceEvent(productId, productName, productPrice, 'remove', {}, 'CloseCheckout')
        },
        success: async (hostedPageId) => {
          registerEcommerceEvent(productId, productName, productPrice, 'purchase', { 'id': hostedPageId, 'revenue': productPrice }, 'FinishCheckout')
          await this.props.updateAccount(true)
          this.chargebee.closeAll()
          callback()
        },
        step: function (value) {
          let step

          if (value === 'review_screen') {
            step = 1
          }

          if (step) {
            registerEcommerceEvent(productId, productName, productPrice, 'checkout', { 'step': step }, 'NextCheckoutStep')
          }
        }
      })
    }

    this.getCheckoutForProduct = (productId, productName, productPrice, getHostedPage, callback) => {
      const { isAuthenticated, signup } = this.props

      registerProductView(productId, productName)

      return () => {
        registerEcommerceEvent(productId, productName, productPrice, 'add', {}, 'StartCheckout')

        if (isAuthenticated) {
          this.showCheckout(productId, productName, productPrice, getHostedPage, callback)
        } else {
          signup(() => {
            this.showCheckout(productId, productName, productPrice, getHostedPage, callback)
          })
        }
      }
    }

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      showCustomerPortal: this.showCustomerPortal,
      showCheckout: this.showCheckout,
      getCheckoutForProduct: this.getCheckoutForProduct
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.depsLoading && !this.props.depsLoading) {
      this.initChargebee()
    }
  }

  render () {
    // The entire state is passed to the provider
    return (
      <ChargebeeContext.Provider value={this.state}>
        {this.props.children}
      </ChargebeeContext.Provider>
    )
  }
}

ChargebeeProvider.propTypes = {
  depsLoading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  signup: PropTypes.func.isRequired,
  getUserEmail: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired
}

export default withDependencies(['https://js.chargebee.com/v2/chargebee.js'])(withAuth(withAccount(ChargebeeProvider)))
