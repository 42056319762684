import AuthContext from './AuthContext'
import React from 'react'
import auth0 from 'auth0-js'
import empty from 'is-empty'
import { getRootUrl } from '../commons/helpers'
import store2 from 'store2'

class AuthProvider extends React.Component {
  constructor (props) {
    super(props)

    this.signup = (callback) => {
      this.auth0.popup.authorize({ login_hint: 'signUp' }, () => this.loginCallback(this.store('authError'), this.store('authResult'), callback))
    }

    this.login = (popup = true, callback) => {
      if (popup) {
        this.auth0.popup.authorize({}, () => this.loginCallback(this.store('authError'), this.store('authResult'), callback))
      } else {
        this.auth0.authorize({}, () => this.loginCallback(this.store('authError'), this.store('authResult'), callback))
      }
    }

    this.logout = () => {
      this.removeSession()
      this.auth0.logout({
        returnTo: getRootUrl()
      })
    }

    this.getUserEmail = () => {
      const { user } = this.state

      if (empty(user) || empty(user.email)) {
        return false
      } else {
        return user.email
      }
    }

    this.getUserName = () => {
      const { user } = this.state

      if (empty(user) || empty(user.nickname)) {
        return false
      } else {
        return user.nickname
      }
    }

    this.loginCallback = async (error, authResult, callback) => {
      if (error || empty(authResult)) {
        this.removeSession()
        return false
      } else {
        await this.setSession(authResult)
        if (typeof callback === 'function') {
          callback()
        }
        return true
      }
    }

    this.handleCallbackPage = () => {
      this.auth0.parseHash((error, authResult) => {
        this.store('authError', error)
        this.store('authResult', authResult)

        this.setState(state => {
          state.authError = error
          return state
        })

        if (!error) {
          setInterval(() => {
            if (!empty(this.store('authResult'))) {
              this.auth0.popup.callback()
            }
          }, 500)
        }
      })
    }

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      authError: {},
      isAuthenticated: false,
      user: {},
      signup: this.signup,
      login: this.login,
      logout: this.logout,
      getUserEmail: this.getUserEmail,
      getUserName: this.getUserName,
      loginCallback: this.loginCallback,
      handleCallbackPage: this.handleCallbackPage
    }

    if (process.browser) {
      this.auth0 = new auth0.WebAuth({
        domain: process.env.GATSBY_AUTH0_DOMAIN,
        clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
        redirectUri: getRootUrl() + '/callback',
        responseType: 'token id_token',
        scope: 'openid profile email'
      })
    }

    this.store = store2.namespace(process.env.GATSBY_AUTH0_LOCALSTORAGE_PREFIX)

    this.loginCallback = this.loginCallback.bind(this)
    this.removeSession = this.removeSession.bind(this)
    this.setSession = this.setSession.bind(this)
  }

  componentDidMount () {
    if (!empty(this.store('authResult'))) {
      this.loginCallback(false, this.store('authResult'))
    } else {
      this.auth0.checkSession({}, this.loginCallback)
    }
  }

  removeSession () {
    this.store.remove('authError')
    this.store.remove('authResult')

    this.setState(state => {
      state.isAuthenticated = false
      state.user = {}
      return state
    })
  }

  setSession (authResult) {
    this.setState(state => {
      state.isAuthenticated = true
      return state
    })

    // When doing a "silent auth" this wont be set on callback page, so lets save it for communication with API
    if (empty(this.store('authResult'))) {
      this.store('authResult', authResult)
    }

    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(authResult.accessToken, (error, user) => {
        if (error) {
          reject(error)
        }

        this.setState(state => {
          state.user = user
          return state
        })

        resolve(true)
      })
    })
  }

  render () {
    // The entire state is passed to the provider
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export default AuthProvider
