/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import AccountProvider from './src/utils/account/AccountProvider'
import ApolloClientProvider from './src/utils/apollo-client/ApolloClientProvider'
import AuthProvider from './src/utils/auth0/AuthProvider'
import ChargebeeProvider from './src/utils/account/ChargebeeProvider'
import LoadingIndicator from './src/components/informational/LoadingIndicator'
import LoadingStatusProvider from './src/utils/loading-status/LoadingStatusProvider'
import React from 'react'
import withMaterialUI from './src/components/hocs/withMaterialUI'

const WithMaterialUI = withMaterialUI(props => props.children)

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <ApolloClientProvider>
      <AccountProvider forceSubscriptionCheck={false}>
        <ChargebeeProvider>
          <WithMaterialUI key={Math.random()}>
            {element}
          </WithMaterialUI>
        </ChargebeeProvider>
      </AccountProvider>
    </ApolloClientProvider>
  </AuthProvider>
)

export const wrapPageElement = ({ element }) => (
  <LoadingStatusProvider>
    <LoadingIndicator />
    {element}
  </LoadingStatusProvider>
)

// Force refresh once a new version of offline service worker is installed
export const onServiceWorkerUpdateReady = () => {
  window.location.reload(true)
}
