import { Query } from 'react-apollo'
import React from 'react'
import empty from 'is-empty'

export default function withQuery (query, variables, context = null) {
  return function (Component) {
    class WithQuery extends React.Component {
      constructor (props) {
        super(props)

        this.queryIsLoading = true
        this.queryHasError = false
        this.data = null

        this.getVariables = this.getVariables.bind(this)
        this.isLoading = this.isLoading.bind(this)
      }

      getVariables () {
        let variablesObject = {}
        variables.forEach(variable => {
          variablesObject[variable] = this.props[variable]
        })
        return variablesObject
      }

      isLoading () {
        if (
          this.queryIsLoading ||
          empty(this.data) ||
          empty(this.data[Object.keys(this.data)[0]])
        ) {
          return true
        } else {
          return false
        }
      }

      render () {
        const variables = this.getVariables()

        return (
          <Query query={query} variables={variables} errorPolicy='all'>
            {({ loading, error, data, refetch, stopPolling, startPolling }) => {
              this.queryIsLoading = loading
              this.queryHasError = error
              this.data = data

              startPolling(process.env.GATSBY_API_POLLING_INTERVAL)

              return (
                <Component
                  loading={this.isLoading()}
                  data={data}
                  refetch={refetch}
                  startPolling={startPolling}
                  stopPolling={stopPolling}
                  {...this.props}
                />
              )
            }}
          </Query>
        )
      }
    }
    return WithQuery
  }
}
