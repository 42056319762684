import LinearProgress from '@material-ui/core/LinearProgress'
import React from 'react'
import withLoadingStatus from '../hocs/withLoadingStatus'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  onMobile: {
    zIndex: 2147483647 /* max int32 z-index */
  },
  onDesktop: {
    zIndex: 1299
  }
})

const LoadingIndicator = ({ classes, isLoading, fullScreen }) => {
  let className = `${classes.root} `

  if (fullScreen) {
    className += classes.onMobile
  } else {
    className += classes.onDesktop
  }

  return (
    <>
      {isLoading() &&
        <LinearProgress className={className} />
      }
    </>
  )
}

export default withStyles(styles)(withLoadingStatus(withMobileDialog()(LoadingIndicator)))
