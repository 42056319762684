import React from 'react'

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const ChargebeeContext = React.createContext({
  showCustomerPortal: function () {},
  showCheckout: function () {},
  getCheckoutForProduct: function () {}
})

export default ChargebeeContext
