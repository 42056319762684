import ApolloClient from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import React from 'react'
import batchHttpLink from './batchHttpLink'
import empty from 'is-empty'
import authLink from './authLink'
import inMemoryCache from './inMemoryCache'
import timeoutLink from './timeoutLink'

class ApolloClientProvider extends React.Component {
  render () {
    if (empty(this.apolloClient)) {
      this.apolloClient = new ApolloClient({
        link: authLink.concat(timeoutLink.concat(batchHttpLink)),
        cache: inMemoryCache,
        shouldBatch: true
      })
    }

    return (
      <ApolloProvider client={this.apolloClient}>
        {this.props.children}
      </ApolloProvider>
    )
  }
}

export default ApolloClientProvider
