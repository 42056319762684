import React from 'react'

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const AccountContext = React.createContext({
  subscription: {},
  updateAccount: function () {},
  updateSubscription: function () {}
})

export default AccountContext
