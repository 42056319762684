import LoadingStatusContext from './LoadingStatusContext'
import React from 'react'

class LoadingStatusProvider extends React.Component {
  constructor (props) {
    super(props)

    this.setReadyMessage = (message) => {
      this.setState(state => {
        state.readyMessage = message
        return state.readyMessage
      })
    }

    this.setLoadingEvent = (message) => {
      const id = Math.random()
      this.setState(state => {
        state.loadingEvents[id] = message
        return state.loadingEvents
      })
      return id
    }

    this.removeLoadingEvent = (id) => {
      this.setState(state => {
        delete state.loadingEvents[id]
        return state.loadingEvents
      })
    }

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      loadingEvents: {},
      readyMessage: '',
      setReadyMessage: this.setReadyMessage,
      setLoadingEvent: this.setLoadingEvent,
      removeLoadingEvent: this.removeLoadingEvent
    }
  }

  render () {
    // The entire state is passed to the provider
    return (
      <LoadingStatusContext.Provider value={this.state}>
        {this.props.children}
      </LoadingStatusContext.Provider>
    )
  }
}

export default LoadingStatusProvider
