import blue from '@material-ui/core/colors/blue'
import { createMuiTheme } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h1: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: '5.5rem',
      fontWeight: 800,
      fontStyle: 'italic'
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 300
    },
    h3: {
      fontSize: '2.25rem',
      fontWeight: 300
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 300
    }
  },
  palette: {
    primary: {
      light: blue[500],
      main: blue[700],
      dark: blue[800]
    },
    secondary: {
      light: green[400],
      main: green[600],
      dark: green[800]
    },
    background: {
      default: 'white'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        whiteSpace: 'nowrap'
      }
    },
    MuiInputBase: {
      input: {
        fontSize: 16
      }
    }
  }
})

theme.mixins = {
  ...theme.mixins,
  '@global': {
    html: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    }
  },
  strong: {
    fontWeight: 'bold'
  },
  seoStrong: {
    fontWeight: 'inherit'
  },
  fullWidth: {
    width: '100%'
  },
  textPrimary: {
    color: theme.palette.primary.main
  },
  textSecondary: {
    color: theme.palette.secondary.main
  },
  clearBackground: {
    background: 'white'
  },
  primaryBackground: {
    backgroundImage: 'linear-gradient(to top, #accbee 0%, #e7f0fd 100%)'
  },
  secondaryBackground: {
    backgroundImage: 'linear-gradient(120deg, rgb(253, 251, 251) 0%, rgb(235, 237, 238) 100%)'
  },
  alternativeBackground: {
    backgroundImage: 'linear-gradient(to top, #ffffff 0%, #edeff3 100%)'
  },
  flex: {
    flex: 1
  },
  section: {
    margin: '5vh auto'
  },
  subSection: {
    marginTop: '4vh'
  },
  columnIcon: {
    verticalAlign: 'middle',
    marginBottom: '2vh'
  },
  paper: {
    padding: '5%',
    marginTop: '5vh',
    [theme.breakpoints.up('sm')]: {
      minWidth: '50vw'
    }
  }
}

theme.overrides = {
  ...theme.overrides,
  MuiPaper: {
    root: {
      ...theme.mixins.secondaryBackground
    }
  },
  MuiGrid: {
    container: {
      '&$spacing-xs-40': {
        [theme.breakpoints.down('xs')]: {
          width: '100% !important',
          marginLeft: '0 !important',
          marginRight: '0 !important'
        },
        '& $item': {
          [theme.breakpoints.down('xs')]: {
            padding: '20px 0'
          }
        }
      },
      '&$spacing-xs-16': {
        [theme.breakpoints.down('xs')]: {
          width: '100% !important',
          marginLeft: '0 !important',
          marginRight: '0 !important'
        },
        '& $item': {
          [theme.breakpoints.down('xs')]: {
            padding: '8px 0'
          }
        }
      }
    }
  }
}

export default theme
