import CssBaseline from '@material-ui/core/CssBaseline'
import JssProvider from 'react-jss/lib/JssProvider'
import { MuiThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import getPageContext from '../../utils/material-ui/getPageContext'

function withMaterialUI (Component) {
  class WithMaterialUI extends React.Component {
    constructor (props) {
      super(props)
      this.muiPageContext = getPageContext()
    }

    componentDidMount () {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side')
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles)
      }
    }

    render () {
      return (
        <>
          <JssProvider generateClassName={this.muiPageContext.generateClassName}>
            {/* MuiThemeProvider makes the theme available down the React
                tree thanks to React context. */}
            <MuiThemeProvider
              theme={this.muiPageContext.theme}
              sheetsManager={this.muiPageContext.sheetsManager}
            >
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <Component {...this.props} />
            </MuiThemeProvider>
          </JssProvider>
        </>
      )
    }
  }
  return WithMaterialUI
}

export default withMaterialUI
