// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-download-source-code-website-js": () => import("./../../../src/pages/download-source-code-website.js" /* webpackChunkName: "component---src-pages-download-source-code-website-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-download-js": () => import("./../../../src/pages/preview-download.js" /* webpackChunkName: "component---src-pages-preview-download-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-wayback-machine-downloader-js": () => import("./../../../src/pages/wayback-machine-downloader.js" /* webpackChunkName: "component---src-pages-wayback-machine-downloader-js" */),
  "component---src-pages-wayback-machine-downloader-preview-download-js": () => import("./../../../src/pages/wayback-machine-downloader/preview-download.js" /* webpackChunkName: "component---src-pages-wayback-machine-downloader-preview-download-js" */)
}

