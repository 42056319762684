import AccountContext from '../../utils/account/AccountContext'
import React from 'react'

const withAccount = Component =>
  props => (
    <AccountContext.Consumer>
      {context => <Component {...context} {...props} />}
    </AccountContext.Consumer>
  )

export default withAccount
