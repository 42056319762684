import React from 'react'
import ReactDependentScript from 'react-dependent-script'

export default function withDependencies (dependencies) {
  return function (Component) {
    class WithDependencies extends React.Component {
      constructor (props) {
        super(props)

        this.renderChildren = (loading = false) => {
          return (
            <>
              <Component depsLoading={loading} {...this.props} />
            </>
          )
        }
      }

      render () {
        if (!process.browser) {
          return (
            <Component {...this.props} />
          )
        } else {
          return (
            <ReactDependentScript
              loadingComponent={this.renderChildren(true)}
              scripts={dependencies}
              renderChildren={this.renderChildren}
            />
          )
        }
      }
    }
    return WithDependencies
  }
}
