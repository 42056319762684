import { setContext } from 'apollo-link-context'
import store2 from 'store2'

const store = store2.namespace(process.env.GATSBY_AUTH0_LOCALSTORAGE_PREFIX)

const authLink = setContext((_, { headers }) => {
  let context = null

  // get the authentication token from local storage if it exists
  if (store('authResult')) {
    context = {
      headers: {
        ...headers,
        authorization: store('authResult').idToken
      }
    }
  }

  // return the headers to the context so httpLink can read them
  return context
})

export default authLink
