import empty from 'is-empty'
import { getDomain } from 'tldjs'
import normalizeUrl from 'normalize-url'
import urlParse from 'url-parse'
/* global __PATH_PREFIX__ */

export function downloadSubFolder (url) {
  url = parseUrl(url)

  if (!empty(url.pathname)) {
    const subFolderFound = url.pathname.match(/^(\S+\/)([^\/]+)?$/)
    return subFolderFound ? subFolderFound[1] : false
  } else {
    return false
  }
}

export function filterFilesByCategory (files, category) {
  return files.filter(file => {
    if (category === file.type.simple) {
      return true
    } else {
      return false
    }
  })
}

export function getPageCountText (pageCount) {
  if (pageCount === 1) {
    return pageCount + ' page'
  } else {
    return pageCount + ' pages'
  }
}

export function getPathPrefix () {
  return __PATH_PREFIX__
}

export function getRootUrl () {
  if (!process.browser) {
    return null
  }

  return window.location.href.substring(0, window.location.href.lastIndexOf(window.location.pathname)) + getPathPrefix()
}

export function getSplat (props) {
  // Check if the trailing slash has been removed by the router, and return it back if so
  const splat = props.location.href.slice(-1) === '/' ? props['*'] + '/' : props['*']
  return empty(splat) ? '' : splat
}

export function isNumeric (value) {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

export function isUrl (value) {
  // Should contain at least one dot
  if (/^[^.]+$/.test(value)) {
    return false
  }

  // First or last char shouldn't be a dot
  if (value.startsWith('.') || value.endsWith('.')) {
    return false
  }

  return true
}

export function isGoogleUrl (url) {
  return getDomain(url).startsWith('google.')
}

export function shouldDownloadFirstPage (url) {
  return new RegExp(process.env.GATSBY_FIRSTPAGE_DOMAINS).test(getDomain(url))
}

export function parseUrl (url) {
  return !empty(url) ? urlParse(normalizeUrl(url, { stripWWW: false, removeTrailingSlash: false })) : {}
}

export function removeUrlScheme (url) {
  url = parseUrl(url)
  url.set('protocol', '')
  url.set('slashes', false)
  return url.href
}

export function urlIsNotRoot (url) {
  url = parseUrl(url)
  return !empty(url.pathname) && url.pathname !== '/'
}

export function registerEcommerceEvent (productId, productName, productPrice, actionName, actionValues, eventName) {
  if (process.browser && window.ga) {
    window.ga('require', 'ec')

    window.ga('ec:addProduct', {
      'id': productId,
      'name': productName,
      'price': productPrice,
      'quantity': 1
    })

    window.ga('ec:setAction', actionName, actionValues)
    window.ga('send', 'event', 'UX', 'click', eventName)
  }
}

export function registerProductView (productId, productName) {
  if (process.browser && window.ga) {
    window.ga('require', 'ec')

    window.ga('ec:addProduct', {
      'id': productId,
      'name': productName
    })

    window.ga('ec:setAction', 'detail')
    window.ga('send', 'event', 'Ecommerce', 'Detail', { 'nonInteraction': 1 })
  }
}
