import LoadingStatusContext from '../../utils/loading-status/LoadingStatusContext'
import React from 'react'
import empty from 'is-empty'

export default function withLoadingStatus (Component) {
  const WithLoadingStatus = (props) => (
    <LoadingStatusContext.Consumer>
      {({ loadingEvents, readyMessage, setLoadingEvent, setReadyMessage, removeLoadingEvent }) => (
        <Component
          loadingEvents={loadingEvents}
          readyMessage={readyMessage}
          setLoadingEvent={setLoadingEvent}
          setReadyMessage={setReadyMessage}
          removeLoadingEvent={removeLoadingEvent}
          isLoading={() => (!empty(loadingEvents))}
          {...props}
        />
      )}
    </LoadingStatusContext.Consumer>
  )
  return WithLoadingStatus
}
