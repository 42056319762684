import AccountContext from './AccountContext'
import React from 'react'
import empty from 'is-empty'
import gql from 'graphql-tag'
import withAuth from '../../components/hocs/withAuth'
import withQuery from '../../components/hocs/withQuery'

const query = gql`
  query AccountQuery($forceSubscriptionCheck: Boolean!) {
    account(forceSubscriptionCheck: $forceSubscriptionCheck) {
      id
      isAuthenticated
      subscription {
        type {
          main
          wayback
        }
        access {
          main
          wayback
        }
      }
    }
  }
`

class AccountProvider extends React.Component {
  constructor (props) {
    super(props)

    this.updateAccount = async (forceSubscriptionCheck = false) => {
      await this.props.refetch({ forceSubscriptionCheck: forceSubscriptionCheck })
    }

    this.updateSubscription = (subscription) => {
      this.setState(state => ({
        subscription: subscription
      }))
    }

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      subscription: {
        type: {
          main: 'none',
          wayback: 'none'
        },
        access: {
          main: [],
          wayback: []
        }
      },
      updateAccount: this.updateAccount,
      updateSubscription: this.updateSubscription
    }

    this.checkAuthenticationStatus = this.checkAuthenticationStatus.bind(this)
  }

  checkAuthenticationStatus (isAuthenticated) {
    // Server response doesnt match client, eg. jwt expired
    if (this.props.isAuthenticated && !isAuthenticated) {
      this.props.logout()
    }
  }

  componentDidMount () {
    if (!empty(this.props.data) && !empty(this.props.data.account)) {
      this.checkAuthenticationStatus(this.props.data.account.isAuthenticated)
      this.updateSubscription(this.props.data.account.subscription)
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.props.loading) {
      if ((prevProps.loading) || prevProps.data.account.subscription !== this.props.data.account.subscription) {
        this.checkAuthenticationStatus(this.props.data.account.isAuthenticated)
        this.updateSubscription(this.props.data.account.subscription)
      }

      if (this.props.data.account.subscription.type.main === 'paid' || this.props.data.account.subscription.type.wayback === 'paid') {
        this.props.stopPolling()
      }
    }

    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.updateAccount()
    }
  }

  render () {
    // The entire state is passed to the provider
    return (
      <AccountContext.Provider value={this.state}>
        {this.props.children}
      </AccountContext.Provider>
    )
  }
}

export default withQuery(query, ['forceSubscriptionCheck'])(withAuth(AccountProvider))
