import React from 'react'

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const AuthContext = React.createContext({
  authError: {},
  isAuthenticated: false,
  user: {},
  signup: function () {},
  login: function () {},
  logout: function () {},
  getUserEmail: function () {},
  getUserName: function () {},
  loginCallback: function () {},
  handleCallbackPage: function () {}
})

export default AuthContext
