import React from 'react'

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
const LoadingStatusContext = React.createContext({
  loadingEvents: {},
  readyMessage: '',
  setReadyMessage: function () {},
  setLoadingEvent: function () {},
  removeLoadingEvent: function () {}
})

export default LoadingStatusContext
